.popup {
  position: fixed;
  z-index: 4;
  transform: scale(0) translate(-50%, -50%);
  transform-origin: top left;
  left: 50%;
  top: 50%;

  width: 290px;

  padding: 20px 15px;
  background: linear-gradient(0.8deg, #dfe9ff 3.7%, #ffffff 97.76%);
  box-shadow: 2px 12px 8px rgba(0, 0, 0, 0.125164);
  border-radius: 2px;

  transition: transform 0.2s;
  display: flex;
  flex-direction: column;

  &.shown {
    transform: scale(1) translate(-50%, -50%);
  }
  &__close {
    align-self: flex-end;
    cursor: pointer;
  }
  &__text {
    align-self: center;
    margin: 20px 0 10px;
  }
  &__btns {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    min-width: 260px;
    margin-top: 20px;
    .btn {
      width: 100%;
    }
  }
  &__photo {
    margin: 20px auto;
  }
}

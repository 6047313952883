.login-bar {
  height: calc(100vh - 55px);
  background: linear-gradient(0.8deg, #dfe9ff 3.7%, #ffffff 97.76%);
  box-shadow: 2px 12px 8px rgba(0, 0, 0, 0.206676);
  transform: translateX(calc(-100% - 12px));
  transition: transform 0.3s ease;
  position: absolute;
  width: 278px;
  z-index: 2;
  display: grid;
  grid-auto-rows: max-content;
  justify-items: center;
  top: 55px;
  left: 0;
  grid-row-gap: 20px;
  overflow: auto;
  &.open {
    transform: translateX(0);
  }
  &__title {
    font-size: 21px;
    font-weight: normal;
    align-self: center;
  }
  .box {
    align-self: center;
  }
}

.login-content {
  height: calc(100vh - 55px);
  background: linear-gradient(0.8deg, #dfe9ff 3.7%, #ffffff 97.76%);
  box-shadow: 2px 12px 8px rgba(0, 0, 0, 0.206676);
  transform: translateX(calc(-100% - 12px));
  transition: transform 0.3s ease;
  position: absolute;
  width: 278px;
  z-index: 2;
  display: grid;
  grid-auto-rows: max-content;
  justify-items: center;
  top: 55px;
  left: 0;
  grid-row-gap: 20px;
  overflow: auto;
  &.open {
    transform: translateX(0);
  }
  .box {
    align-self: center;
  }
  &__link {
    font-size: 17px;
    display: flex;
    align-items: center;
    padding: 10px 0;
    .role {
      font-weight: bold;
      text-decoration: underline;
      margin-left: 5px;
    }
    .arrow {
      margin-right: 15px;
    }
  }
}

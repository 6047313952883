.survey {
  padding-bottom: 1px;
  min-height: calc(100vh - 136px);
  display: flex;
  flex-direction: column;
  &__description {
    padding: 50px 65px;
    font-size: 14px;
    line-height: 23px;
  }
  &__select {
    width: 226px;
    margin: 20px auto;
  }
  &__btn {
    margin: 55px auto;
    width: 187px;
    &--next {
      margin: 0 0 0 auto;
    }
    &--mt0 {
      margin-top: 0;
    }
    &--m0auto {
      margin: 0 auto;
    }
    &--wide {
      width: 300px;
      margin: 30px auto;
    }
  }
  &__nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 34px 40px;
    &--center {
      justify-content: center;
    }

    &--bottom {
      margin-top: auto;
      .btn {
        margin: 0;
        width: 139px;
      }
    }
  }
  &__question {
    padding: 0 53px;
  }
}

.s-question {
  &__title {
    font-weight: bold;
    font-size: 16px;
    line-height: 23px;
    margin-bottom: 20px;
  }

  &__description {
    font-style: italic;

    font-size: 14px;
    line-height: 23px;
    margin-bottom: 20px;
  }

  &__radio {
    font-weight: bold;
    font-size: 16px;
    line-height: 23px;
    margin-top: 20px;
    display: inline-block;
    @media (min-width: 768px) {
      width: calc(50% - 2px);
    }
    width: 100%;
  }
}

.s-top-nav {
  &__counter {
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
  }
}

.audio {
  &__player {
    margin: 20px auto;
    display: flex;
  }

  &__btn {
    width: 100%;
  }
  &__controls {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
  }
}

.photo {
  max-height: 360px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  max-width: 640px;
  object-fit: cover;
}

.video {
  position: relative;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  height: calc(100vh - 215px);
  //height: calc(100vh - 345px);
  max-width: 640px;
  max-height: 480px;
  &--wide {
    max-height: 360px;
    height: auto;
  }
  video {
    width: 100%;
    max-width: 640px;
  }
  canvas {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0px;
  }
  &__btn {
    width: 187px;
    z-index: 1;
    position: absolute;
  }
}

.expressions {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  max-width: 640px;
  margin: 10px auto 0;
  width: 100%;
  &__item {
    margin: 5px 0;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    align-self: center;
    img {
      margin: 0 auto 2.5px;
    }
    &--info {
      grid-column: -1/1;
    }
    &.btn {
      margin: 18.5px 0 10px;
    }
  }
}

@keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
}

@keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}

.progress {
  position: relative;
  height: 4px;
  display: block;
  width: 100%;

  border-radius: 2px;
  margin: 0.5rem 0 1rem 0;
  overflow: hidden;
  grid-column: -1/1;
  &.hidden {
    display: none;
  }
  &--m2 {
    background-color: #acece6;
    .indeterminate {
      background-color: $green;
    }
  }
  &--m1 {
    background-color: #c6e4ff;
    .indeterminate {
      background-color: $blue;
    }
  }
  .indeterminate {
    &:before {
      content: '';
      position: absolute;
      background-color: inherit;
      top: 0;
      left: 0;
      bottom: 0;
      will-change: left, right;
      animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
    }
    &:after {
      content: '';
      position: absolute;
      background-color: inherit;
      top: 0;
      left: 0;
      bottom: 0;
      will-change: left, right;
      animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
      animation-delay: 1.15s;
    }
  }
}

.timer {
  align-self: center;
  margin-bottom: -34px;
  //position: absolute;
  //right: 0;
  //top:0;
  background-color: $green;
  border-radius: 0 0 0 12px;
  padding: 6px;
  font-size: 18px;
  font-weight: 700;
  z-index: 1;
}

.results {
  padding: 0 34px;
  &__item {
    background-color: #eae2cc;
    margin-bottom: 10px;
    padding: 12px 17px 17px;
  }
  &__total {
    text-align: left;
    margin-bottom: 10px;
    span {
      font-weight: bold;
    }
  }
}

.r-item {
  &__title {
    font-weight: bold;
    font-size: 15px;
    line-height: 17px;
    margin-bottom: 2.5px;
  }
  &__description {
    font-size: 13px;
    line-height: 17px;
  }
}

ol,
ul {
  margin: 45px 0;
  ul {
    margin: 20px 0;
    padding-left: 15px;
  }
}

ul.program-list {
  margin: 45px 0;
  padding-left: 17px;
  list-style-type: disc;
  display: grid;
  grid-template-columns: 1fr 50px;

  li:nth-of-type(5) {
    &:before {
      content: '';
      border-bottom: 3px solid #78bc61;
      display: block;
      width: calc(100% + 15px);
      margin-left: -15px;
      position: relative;
      top: -10px;
      grid-column: -1/1;
    }
  }
}

.vertical {
  &-item {
    border: 1px solid #78bc61;
    writing-mode: vertical-rl;
    font-size: 16px;
    grid-row: 1/5;
    grid-column: 2/2;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    &:nth-of-type(2) {
      grid-column: 2/2;
      margin-top: -11px;
      background-color: lightgrey;
      border-top: 4px solid #78bc61;
    }
  }
}

.horizontal {
  &-item {
    grid-row: 1/1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 5px 0 5px;

    font-size: 12px;

    &:nth-of-type(2) {
      grid-row: 2/2;
    }
    &:nth-of-type(3) {
      grid-row: 3/3;
    }
    &:nth-of-type(4) {
      grid-row: 4/4;
    }
  }
}

.list {
  &-item {
    font-size: 16px;
    line-height: 22px;
    position: relative;
    margin-bottom: 10px;
    &:after {
      content: '';
      border-bottom: 1px solid;
      display: block;
      width: calc(100% + 15px);
      margin-left: -15px;
      padding-top: 10px;
      grid-column: -1/1;
    }
    &:last-of-type {
      margin-bottom: 0;
      &:after {
        border-bottom: 0;
      }
    }
    &--grass-green {
      &:after {
        border-color: #78bc61;
      }
    }
    .MuiSvgIcon-root {
      vertical-align: bottom;
    }
    &--program {
      display: grid;
      grid-template-columns: auto 1fr auto;
      grid-column-gap: 10px;
      .profile__edit {
        padding-right: 20px;
      }
    }
  }
}

.media-container {
  position: relative;
}

.media-name {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #fff;
  padding: 2px;
}

.table-row {
  &.solved {
    background: linear-gradient(270deg, #76d0b5 1.02%, #61c6a8 100%);
  }
}

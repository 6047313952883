.custom-select {
  appearance: none;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOSIgaGVpZ2h0PSIxMSIgdmlld0JveD0iMCAwIDkgMTEiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMCA2LjU5NzEzTDAuNzY5MjAxIDUuNTQwMjRMMy44NDU1OCA4LjU4MjM5TDMuODQ1NTggMEw1LjE1NDQyIDBMNS4xNTQ0MiA4LjU4MjM5TDguMjMwOCA1LjU0MDI0TDkgNi41OTcxM0w0LjUwMDIyIDExTDAgNi41OTcxM1oiIGZpbGw9IiMyQTJFM0UiLz4KPC9zdmc+Cg==');
  background-position: right 7px top 50%, 0 0;
  background-repeat: no-repeat, repeat;
  background-size: 11px auto, 100%;
  display: block;
  height: 30px;
  border: 1px solid #52b2ff;
  font-size: 13px;
  font-weight: bold;
  padding: 0 7px;
  background-color: white;
  width: 100%;
  margin-bottom: 7.5px;
}

input[type='file'] {
  display: none;
}

input {
  border-radius: 0;
}

.switch {
  justify-self: flex-end;
  align-self: center;
  display: flex;
  flex-direction: column;
  &__text {
    font-size: 13px;
    line-height: 16px;
    margin-bottom: 15px;
  }
}

.switch__input {
  height: 0;
  width: 0;
  visibility: hidden;
  margin: 0;
}

.switch__label {
  cursor: pointer;
  text-indent: -9999px;
  width: 60px;
  height: 30px;
  display: block;
  background: #cdd3d9;
  border-radius: 12.5px;
  position: relative;
  align-self: center;
}

.switch__label:after {
  content: '';
  position: absolute;
  top: 3px;
  left: 5px;
  width: 24px;
  height: 24px;
  background: #252525;
  border-radius: 27px;
  transition: 0.3s;
}

.switch__input:checked + .switch__label {
  background-color: $green;
}

.switch__input:checked + .switch__label:after {
  left: calc(100% - 5px);
  transform: translateX(-100%);
}

.switch__label:active:after {
  width: 39px;
}

.error {
  color: $red;
}

.helperText {
  color: $orange;
}

.form {
  padding: 24px 33px;
  &__input {
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid #52b2ff;
    width: 100%;

    font-size: 15px;
    line-height: 18px;
    margin: 7.5px 0;
  }
  &__select {
    border: 0;
    border-bottom: 1px solid #52b2ff;
    width: 100%;
    background-color: transparent;

    font-size: 15px;
    line-height: 18px;
    margin: 7.5px 0;
  }
  &__label {
    font-size: 14px;
    line-height: 16px;
    margin-right: 5px;

    color: #008dff;
  }
  &__labels {
    display: flex;
    justify-content: space-between;
  }
  &__item {
    margin: 0 auto 10px;
  }
  &__btn {
    margin: 55px auto 20px;
    width: 197px;
    &.mb-0 {
      margin-bottom: 0;
    }
    &.mt-sm {
      margin-top: 25px;
    }
  }
  label {
    position: relative;
  }
}

.checkbox-label {
  display: flex;
  align-items: flex-start;
  margin: 7.5px 0;
  column-gap: 5px;
}

.form {
  & + div {
    text-align: center;
  }
}

[type='range'] {
  width: 290px;
  -webkit-appearance: none;
  height: 13px;
  border-radius: 6px;
  background: #f1f1f1;
  outline: none;
  padding: 0;
  margin: 15px 0 7.5px;
}

/* custom thumb */
[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #7b6ded;

  border: #f8d77f 5px solid;

  cursor: pointer;
  -webkit-transition: background 0.15s ease-in-out;
  transition: background 0.15s ease-in-out;
}

[type='range']::-webkit-slider-thumb:hover {
  background: #efa070;
}

[type='range']::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border: 0;
  border-radius: 50%;

  background: #efa070;

  border: #f8d77f 5px solid;

  cursor: pointer;
  -webkit-transition: background 0.15s ease-in-out;
  transition: background 0.15s ease-in-out;
}

[type='range']::-moz-range-thumb:hover {
  background: #f8d77f;
}

/* remove border */
input::-moz-focus-inner,
input::-moz-focus-outer {
  border: 0;
}

input[type='checkbox'],
input[type='radio'] {
  appearance: none;
  min-width: 1rem;
  height: 1rem;
  vertical-align: top;
  margin: 0;
  &:before {
    content: '';
    position: absolute;
    display: block;
    width: 1rem;
    height: 1rem;
    border: 2px solid #b5bfd9;
    background-color: #fff;
    border-radius: 4px;
    transition: 0.25s ease;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='192' height='192' fill='%23FFFFFF' viewBox='0 0 256 256'%3E%3Crect width='256' height='256' fill='none'%3E%3C/rect%3E%3Cpolyline points='216 72.005 104 184 48 128.005' fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'%3E%3C/polyline%3E%3C/svg%3E");
    background-size: 12px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }
  &:checked {
    &::before {
      background-color: #2260ff;
      border-color: #2260ff;
    }
  }
}

input[type='radio'] {
  &::before {
    border-radius: 50%;
  }
}

.checkbox-tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 17rem;
  min-height: 7rem;
  border-radius: 0.5rem;
  border: 2px solid #b5bfd9;
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(#000, 0.1);
  transition: 0.15s ease;
  cursor: pointer;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    border: 2px solid #b5bfd9;
    background-color: #fff;
    border-radius: 50%;
    top: 0.25rem;
    left: 0.25rem;
    opacity: 0;
    transform: scale(0);
    transition: 0.25s ease;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='192' height='192' fill='%23FFFFFF' viewBox='0 0 256 256'%3E%3Crect width='256' height='256' fill='none'%3E%3C/rect%3E%3Cpolyline points='216 72.005 104 184 48 128.005' fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'%3E%3C/polyline%3E%3C/svg%3E");
    background-size: 12px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  &:hover {
    border-color: #2260ff;
    &:before {
      transform: scale(1);
      opacity: 1;
    }
  }
}

.checked.checkbox-tile {
  border-color: #2260ff;
  box-shadow: 0 5px 10px rgba(#000, 0.1);

  &:before {
    transform: scale(1);
    opacity: 1;
    background-color: #2260ff;
    border-color: #2260ff;
  }
}
.results__item.checkbox-tile {
  background-color: #fff;
}

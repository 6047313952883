.boxes-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(147px, 1fr));
  gap: 16px;
  @media (min-width: 375px) {
    padding: 15px 32px;
  }
  padding: 15px 25px;
  justify-content: center;
  justify-items: center;
  &--fs {
    justify-items: flex-start;
    grid-template-columns: repeat(auto-fill, minmax(92px, 1fr));
    .box__title {
      text-align: left;
    }
  }
}

.box {
  &__title {
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    font-weight: normal;
    margin-top: 10px;
    &--mt0 {
      margin-top: 0;
    }
    &--bold {
      font-weight: bold;
    }
    .role {
      text-decoration: underline;
      font-weight: bold;
    }
  }
  &__description {
    font-size: 13px;
    line-height: 17px;
  }
  &__arrow {
    position: absolute;
    right: 0;
    bottom: 0;
  }
  &__lock {
    position: absolute;
    left: -1px;
    top: -1px;
  }
  &__checkbox {
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: #373737;
    border-radius: 12px 0px 0px 0px;
    height: 23px;
    width: 23px;
    display: flex;
    //align-items: flex-end;
    //justify-content: flex-end;
    align-items: center;
    justify-content: center;
  }
  &__visuals {
    box-shadow: 1px 2px 4px #dedede;
    border-radius: 0px 26px 0px 34px;
    height: 105px;
    width: 147px;

    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: box-shadow 0.3s ease, border-radius 0.3s ease;
    &:hover {
      box-shadow: 2px 4px 8px #dedede;
      border-radius: 0px 34px 0px 26px;
    }
    &--purple {
      background: $purple;
    }
    &--dark-purple {
      background: #6668fc;
    }
    &--green {
      background: $green;
    }
    &--grass-green {
      background: #78bc61;
    }
    &--blue {
      background: $blue;
    }
    &--blue-green {
      background: #3caab5;
    }
    &--yellow {
      background: $yellow;
    }
    &--red {
      background: $red;
    }
    &--orange {
      background: $orange;
    }
    &--dark {
      background-color: #466282;
    }
    &--square {
      width: 133px;
      height: 126px;
      border-radius: 26px 0 34px 0;
      margin: 0 auto;
    }
    &--square-sm {
      width: 81px;
      height: 79px;
      border-radius: 14px 0 14px 0;
      .box__ico {
        width: 49px;
      }
    }
  }
}

.page-bar {
  height: 81px;
  display: flex;
  align-items: center;
  padding: 0 27px;
  &--purple {
    background: linear-gradient(270deg, #7b6ded 1.02%, #8f88d9 100%);
  }
  &--dark-purple {
    background: linear-gradient(270deg, #8f88d9 1.02%, #6668fc 100%);
  }
  &--green {
    background: linear-gradient(270deg, #76d0b5 1.02%, #61c6a8 100%);
  }
  &--grass-green {
    background: linear-gradient(270deg, #61c6a8 1.02%, #78bc61 100%);
  }
  &--blue {
    background: linear-gradient(270deg, #6aaeec 1.02%, #3691d0 100%);
  }
  &--blue-green {
    background: linear-gradient(270deg, #3691d0 1.02%, #3caab5 100%);
  }
  &--dark {
    background: linear-gradient(270deg, #466282 1.02%, #223864 100%);
  }
  &--yellow {
    background: linear-gradient(270deg, #edcb72 1.02%, #fbd369 100%);
  }
  &--red {
    background: linear-gradient(270deg, #e7a8a8 1.02%, #dc9a99 100%);
  }
  &--orange {
    background: linear-gradient(270deg, $red 1.02%, $orange 100%);
  }
  &__arrow {
    margin-right: 25px;
  }
  &__ico {
    width: 35px;
    margin-right: 15px;
  }
  &__title {
    font-size: 15px;
    line-height: 19px;
    font-weight: normal;
    color: #fff;
  }
  p {
    color: #fff;
    font-size: 13px;
  }
  &.mb-35 {
    margin-bottom: 35px;
  }
}

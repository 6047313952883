@import '_reset';
@import '_variables';
@import '_buttons';
@import '_forms';
@import '_home';
@import '_sidebar';
@import '_games';
@import '_survey';
@import '_lists';
@import '_profile';
@import '_breadcrumbs';
@import '_modals';
@import '_voice-recorder';
@import '~react-notifications-component/dist/scss/notification';

body {
  font-family: 'Roboto Condensed', sans-serif;
  color: #2f2f2f;
  font-size: 14px;
  letter-spacing: 0.0269231px;
  position: relative;
  min-height: 100vh;
  -webkit-text-size-adjust: 100%;
  @media (min-width: 1025px) {
    background-color: #c3c3c3;
    padding: 15px;
  }
  &:after {
    position: absolute;
    content: '';
    background: linear-gradient(0.8deg, #d3daef 3.7%, #ffffff 97.76%);
    mix-blend-mode: normal;
    opacity: 0.46;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -1;
  }
  &.overlay,
  &.overlay-not-head {
    overflow: hidden;
    &:before {
      position: absolute;
      content: '';
      background: #466282;
      mix-blend-mode: normal;
      opacity: 0.38;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 1;
    }
    &--zi3 {
      &:before {
        z-index: 3;
      }
    }
  }
  &.overlay-not-head {
    &:before {
      z-index: 3;
    }
  }
}

a {
  text-decoration: none;
  color: #2f2f2f;
}

.App {
  padding-bottom: 1px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  @media (min-width: 1025px) {
    width: 800px;
    margin: 0px auto;
    overflow: hidden;
    background-color: white;
    min-height: calc(100vh - 30px);
    box-shadow: 0px 0px 5px 5px #cfcfcf;
  }
}

.app-bar {
  &__area {
    z-index: 2;
    position: relative;
    height: 55px;
    display: flex;
    align-items: center;
    padding: 0 17px;
    background-color: white;
  }

  &__title {
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    margin-left: auto;
  }
  &__user {
    font-size: 13px;
    line-height: 23px;
    font-weight: bold;
    display: flex;
    align-items: center;
    margin-left: 40px;
    img {
      margin-right: 5px;
    }
  }
}

.container {
  max-width: 360px;
  width: 100%;
  margin: 0 auto;
  padding: 0 17px;
  &--center {
    text-align: center;
  }
}

.container-full {
  max-width: 820px;
  width: 100%;
  margin: 0 auto;
  padding: 0 17px;
  &.mob-pad0 {
    @media (max-width: 479px) {
      padding: 0;
    }
  }
}

.container-no-pad {
  max-width: 360px;
  width: 100%;
  padding: 35px 0;
}

.img-center {
  margin: 20px auto;
}

.page-heading {
  font-size: 20px;
  margin: 40px 0;
}

.copy {
  font-size: 12px;
  margin-top: 10px;
  text-align: center;
}

.info-text {
  font-size: 13px;
  line-height: 19px;
  padding: 0 0 19px 34px;
}

.login-info {
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  padding-top: 45px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  height: 96px;
  animation-name: spin;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  &--sm {
    height: 34px;
  }
}

.loader {
  display: flex;
  align-items: center;
  &.center {
    justify-content: center;
  }
}

.sound-toggle {
  transition: opacity 0.2s ease;
}

.mt20 {
  margin-top: 20px;
}

.mb20 {
  margin-bottom: 20px;
}

.full {
  width: 100%;
}

.counter {
  text-align: center;
  font-size: 18px;
  margin-top: 10px;
}

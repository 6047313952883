.boxes-list {
  display: grid;
  @media (min-width: 375px) {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    padding: 35px 24px;
  }

  padding: 35px 12px;
  gap: 16px 12px;

  &__item {
    display: grid;
    grid-template-columns: 147px 1fr;
    gap: 6px 18px;
    .box__visuals {
      grid-row: 1/3;
    }
    .box__title {
      text-align: left;
      align-self: flex-end;
    }
  }
}

.games-list {
  display: grid;
  gap: 18px;
  margin-top: 18px;
  &__item {
    border-radius: 0px 26px 0px 0;
    margin-right: 34px;
    grid-template-columns: 1fr 70px;
    background: #c6e4ff;
  }
  &__item-content {
    display: none;
    margin-top: -18px;
    .gl-item {
      grid-template-columns: 1fr 200px;
      background-color: #ddefff;
      border-bottom: 1px solid #3891d1;
      &--results {
        grid-template-columns: auto;
        background-color: rgba($orange, 0.25);
        border-bottom: 1px solid #363636;
        height: auto;
        padding: 0 0 34px 17px;
      }
    }

    &.open {
      display: block;
    }
  }
}

.gl-item {
  box-shadow: 1px 2px 4px #d8d8d8;

  display: grid;

  padding-left: 17px;
  min-height: 70px;
  &.to-do {
    display: none;
    &:nth-child(-n + 4) {
      display: grid;
    }
  }
  &__title {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    align-self: flex-end;
  }
  &__description {
    font-size: 14px;
    line-height: 20px;
    align-self: flex-start;
  }
  &__expand {
    background-color: #3891d1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 70px;
    cursor: pointer;
    border-radius: 0px 26px 0px 0;
    grid-row: 1/3;
    grid-column: 2/2;
  }
  &__buttons {
    grid-row: 1/3;
    grid-column: 2/2;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  &__btn {
    background: #80dec2;
    border-radius: 22px;
    width: 94px;
    height: 37px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    &--red {
      background-color: $red;
    }
  }
  &__arrow {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  &--results {
    grid-template-columns: 1fr 128px;
    background-color: rgba($orange, 0.5);
    border-bottom: 1px solid #363636;
    padding-left: 17px;
    ol {
      padding-left: 17px;
    }
    .gl-item__btn {
      background: #363636;
      color: #fff;
    }
    .gl-item__expand {
      width: 128px;
      background-color: $orange;
    }
  }
}

.tab-menu {
  display: grid;
  grid-template-columns: 50% 50%;
  &-link {
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    color: #fff;
    background: #85bde8;
    padding-left: 33px;
    height: 50px;

    border: none;
    text-align: left;
    &.active {
      background: #3891d1;
    }
  }
  &-result {
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    background: rgba($orange, 0.5);
    padding-left: 33px;
    height: 50px;

    border: none;
    text-align: left;
    &.active {
      background: $orange;
    }
  }
}

.tab-bar-content {
  display: none;
  &.active {
    display: block;
  }
}

.game-area {
  min-height: calc(100vh - 56px);
  background-color: #fff;
  display: flex;
  flex-direction: column;

  .list-item:after {
    border-color: $green;
  }
  &.mb-big {
    margin-bottom: 136px;
  }
}

.eye-game {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 15px 30px;
  justify-items: center;
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  &__item {
    width: 136px;
    height: 136px;
    //width: 182px;
    //height: 182px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    cursor: pointer;
    &:nth-of-type(1) {
      grid-column: 1/4;
      //justify-self: center;
    }
    &:nth-of-type(2) {
      //justify-self: end;
    }
    &:nth-of-type(3) {
      grid-column: 3/4;
    }
    &:nth-of-type(4) {
      grid-column: 1/4;
      justify-self: center;
    }
    &.order-0 {
      grid-column: 1/2;
      grid-row: 2/2;
    }
    &.order-1 {
      grid-column: 3/4;
      grid-row: 2/2;
    }
    &.order-2 {
      grid-column: 1/2;
      grid-row: 3/3;
    }
    &.order-3 {
      grid-column: 3/4;
      grid-row: 3/3;
    }
    &.selected,
    &:focus {
      border: 1px solid #e2c892;
      outline: none;
    }
    &:disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}

.trial-text {
  margin: auto 0 15px 25px;
}

.game-text {
  margin: 0 auto 0 10px;
}

.game-description {
  padding: 17px 27px;
}

.dots {
  background-color: #d3daef61;
  display: flex;
  justify-content: flex-end;
  padding: 15px 0;
  margin-top: auto;
  &__item {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-right: 10px;
    background: #d7dcee;
    &.active {
      background: $blue;
    }
  }
}

.drag_food_to_animals {
  .animals {
    display: flex;
    align-items: flex-end;
    justify-content: space-evenly;
  }
  .animal {
    position: relative;
    border-radius: 30px;
    text-align: center;
  }
  .highlighted > .animal {
    background-color: #eee;
  }
}

.drag_things_to_boxes {
  .box {
    border: 2px solid black;
    min-height: 119px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 1px;
    width: 100%;
  }
  .highlighted {
    .box {
      box-shadow: inset 0 0 4px #000;
    }
  }
  margin: 40px 0;
}
.component_box {
  width: 100%;
}
.box_item_component {
  .outer {
    width: 114px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .highlighted {
    .outer {
      border-top: 40px solid #ddd;
    }
  }
}

.things_to_drag,
.boxes {
  justify-items: center;
  justify-content: center;
  display: grid;
  grid-template-columns: repeat(3, 119px);
  gap: 1px;
}

.boxable_component {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
}

.emotions {
  display: flex;
  justify-content: space-evenly;
  margin: 40px 0;
  cursor: pointer;
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__title {
    font-size: 18px;
    margin-top: 10px;
  }
}

.stats {
  margin-bottom: 10px;
  padding: 12px;
  border-radius: 0.5rem;
  border: 2px solid #b5bfd9;
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(#000, 0.1);
}

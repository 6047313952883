button {
  border: 0;
  background: none;
  cursor: pointer;
  padding: 0;
}

.btn {
  margin: 0 auto;
  display: block;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #363636;

  border-radius: 22px;
  color: #fff;

  &--light {
    background-color: #52b2ff;
    color: #2f2f2f;
  }
  &--green {
    background-color: $green;
    color: #2f2f2f;
  }
  &--grass-green {
    background-color: #78bc61;
    color: #2f2f2f;
  }
  &--no-fill {
    border: 1px solid #52b2ff;
    background-color: transparent;
    color: #2f2f2f;
  }
  &--login {
    width: 187px;
    margin-top: 25px;
  }
  &--upload {
    width: 187px;
  }
  &--read {
    width: 153px;
  }
  &:disabled {
    opacity: 0.25;
    cursor: not-allowed;
  }
  &.disabled {
    opacity: 0.25;
    pointer-events: none;
  }
  &--red {
    background-color: $red;
    &:disabled {
      opacity: 1;
    }
  }
  &--game {
    width: 187px;
    margin-top: 25px;
    margin-left: 0;
  }

  &-start {
    display: flex;
    align-items: center;
    max-width: 280px;
    .btn {
      margin-right: 0;
      font-size: 14px;
      padding: 0 20px;
    }
  }
}

.buttons {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &__item {
    width: 100%;
  }
}

.logout-btn {
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #008dff;

  img {
    margin-bottom: 10px;
  }
}

.profile {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 10px;
  padding: 15px 0;
  .box__visuals--square-sm {
    grid-row: 1/3;
    grid-column: 2/2;
  }
  .logout-btn {
    grid-column: 3/3;
  }
  &__edit {
    grid-column: 3/3;
    color: #4eaaff;
    display: flex;
    align-items: center;
    img {
      margin-left: 5px;
    }
  }
  &__info {
    font-size: 13px;
    margin-top: 5px;
    span {
      color: rgba(#2f2f2f, 0.5);
      min-width: 55px;
      display: inline-block;
    }
  }
  &__select {
    width: 100%;
    margin: 10px 0 20px;
  }
  &__info-ico {
    border-bottom: 1px solid #2a2e3e;
    display: flex;
    align-items: center;
    color: #284a8f;
    font-size: 13px;
    padding-bottom: 10px;
    margin: 40px 0 0;
    img {
      margin-right: 5px;
    }
  }
  &__kid {
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 10px;
    img {
      margin-left: 5px;
    }
    .profile__edit {
      margin-left: auto;
    }
  }
  &__add {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 30px auto;
    img {
      margin-bottom: 15px;
    }
    &--sm {
      border: 1px solid $green;
      padding: 28px;
      margin: 0;
      cursor: pointer;
      &:disabled {
        opacity: 0.25;
        cursor: not-allowed;
      }
      img {
        width: 60px;
      }
    }
    &--fixed {
      position: fixed;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.profile-form {
  max-width: 360px;
  &__grid {
    display: grid;

    grid-template-columns: repeat(2, 1fr);

    grid-column-gap: 15px;
    grid-row-gap: 25px;

    .btn {
      width: 100%;

      &--save {
        grid-column: 2/3;
      }
    }
    &-col-1 {
      grid-column: -1/1;
    }
    .form__item {
      width: 100%;
    }
    &.wrap {
      @media (max-width: 767px) {
        grid-template-columns: auto;
      }
    }
  }
  &__select {
    border: 0;
    border-bottom: 1px solid #52b2ff;
    background-color: transparent;
    padding: 0;
    font-weight: normal;
    color: #b1b1b1;
  }
  input[type='date'] {
    color: #b1b1b1;
    font-size: 13px;
  }
  &__photo {
    border: 1px solid #52b2ff;
    object-fit: cover;
    max-width: 100%;
  }
}

.therapist {
  &__btn {
    display: inline-flex;
    align-items: center;
    margin: 0 15px 25px 0;
    img {
      margin-right: 5px;
    }
    &--cancel {
      color: $red;
    }
    &--confirm {
      color: $green;
    }
  }
  &__group-name {
    margin: 25px 0;
  }
  &__description {
    line-height: 1.4;
    border-bottom: 1px solid $purple;
    padding-bottom: 30px;
    margin-bottom: 30px;
    &:last-of-type {
      border: 0;
    }
  }
}

.boxes-grid-subscription {
  display: grid;
  gap: 16px;
  padding: 15px 0;
  justify-items: flex-start;
  grid-template-columns: repeat(auto-fill, minmax(86px, 1fr));
}

.payment-list {
  margin-bottom: 55px;
}

.payment-bar {
  background-color: $green;
  padding: 17px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  display: block;
  cursor: pointer;
  span {
    text-decoration: underline;
  }
}

.demo-bar {
  padding: 8px 17px;
  text-align: center;
  display: block;
  margin-top: auto;
  font-size: 13px;
  line-height: 19px;
}
